import React from "react";
import Gift from "../instant-card/integrate/Gift";
import NetworkHero from "./integrate/NetworkHero";
import VisaCardCountries from "./integrate/VisaCardCountries";
// import VisaMastercardCountries from "./integrate/VisaMastercardCountries";

const CountriesNetwork = () => {
  return (
    <div className="web-container">
      <NetworkHero />
      <VisaCardCountries />
      {/* <div className="h-[1px] bg-[#F5F5F5]"></div> */}
      {/* <VisaMastercardCountries /> */}
      <div className="h-[1px] bg-[#F5F5F5]"></div>
      <div className="text-zebec-black font-semibold my-10">
        as of August 2024, subject to change
      </div>
      <Gift />
    </div>
  );
};

export default CountriesNetwork;
