import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import * as Images from "../../assets/images/index";
import {
  FooterDiscordIcon,
  FooterInstagramIcon,
  FooterMediumIcon,
  FooterTelegramIcon,
  FooterTwitterIcon,
  FooterYoutubeIcon,
} from "../../assets/icons";
// import ZebecPrivacyPolicyPdf from "../products/zebec-card/ZebecPrivacyPolicy.pdf";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Fragment>
      <div className=" pt-16 pb-8 bg-darkish">
        <div className="web-container">
          <div className="flex flex-col lg:flex-row gap-x-10 gap-y-10 md:gap-y-20 justify-between pb-16 lg:pb-40">
            <NavLink to="/">
              <img
                src={Images.ZebecNetworkWhiteLogo}
                alt="Zebec logo"
                className="max-h-12 w-auto"
              />
            </NavLink>
            <div className="flex flex-col sm:flex-row gap-x-10 gap-y-8">
              <div className="flex flex-col gap-y-2 md:min-w-[150px]">
                <p className="text-body-22 text-[#726CA0] font-ppmori">
                  Products:
                </p>
                <a
                  href="https://zebec.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body-16 text-white hover:text-primary font-ppmori"
                >
                  Zebec
                </a>
                <a
                  href="https://zebec.io/products/rwa-payments"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body-16 text-white hover:text-primary font-ppmori"
                >
                  RWA Payments
                </a>
                <a
                  href="https://zebec.io/products/depin"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body-16 text-white hover:text-primary font-ppmori"
                >
                  DePIN
                </a>
                <a
                  href="https://zebec.io/products/zebec-vaults"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body-16 text-white hover:text-primary font-ppmori"
                >
                  Zebec Vaults
                </a>
                <a
                  href="https://zebec.io/airdrop"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body-16 text-white hover:text-primary font-ppmori"
                >
                  Airdrop
                </a>
              </div>
              <div className="flex flex-col gap-y-2 md:min-w-[150px]">
                {/* Docs */}
                <p className="text-body-22 text-[#726CA0] font-ppmori">
                  Resources:
                </p>
                <a
                  href="https://docs.zebec.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body-16 text-white hover:text-primary font-ppmori"
                >
                  Docs
                </a>
                <a
                  href="mailto:cardsupport@zebec.io"
                  className="text-body-16 text-white hover:text-primary font-ppmori"
                >
                  Help and Support
                </a>
              </div>
              <div className="flex flex-col md:min-w-[150px]">
                <p className="text-body-22 text-[#726CA0] mb-4">Company:</p>
                <ul className="flex flex-col gap-y-2 text-body-16 text-zn-white">
                  <li>
                    <a
                      href="https://zebec.io/company"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-body-16 text-white hover:text-primary font-ppmori"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://zebec.io/governance"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-body-16 text-white hover:text-primary font-ppmori"
                    >
                      Governance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://zebec.io/company#team"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-body-16 text-white hover:text-primary font-ppmori"
                    >
                      Team
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://zebec.io/company#in_the_press"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-body-16 text-white hover:text-primary font-ppmori"
                    >
                      Press
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://zebec.io/blogs"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-body-16 text-white hover:text-primary font-ppmori"
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col md:min-w-[150px]">
                <p className="text-body-22 text-[#726CA0] mb-4">Follow us:</p>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-4">
                    <a
                      href="https://twitter.com/Zebec_HQ"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:opacity-80"
                    >
                      <FooterTwitterIcon />
                    </a>
                    <a
                      href="https://discord.gg/fJM9cHuvvB"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:opacity-80"
                    >
                      <FooterDiscordIcon />
                    </a>
                    <a
                      href="https://t.me/zebecprotocol"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:opacity-80"
                    >
                      <FooterTelegramIcon />
                    </a>
                  </div>
                  <div className="flex items-center gap-4">
                    <a
                      href="https://www.instagram.com/zebec_hq/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:opacity-80"
                    >
                      <FooterInstagramIcon />
                    </a>
                    <a
                      href="https://www.youtube.com/@Zebec_HQ"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:opacity-80"
                    >
                      <FooterYoutubeIcon />
                    </a>
                    <a
                      href="https://medium.com/zebec-protocol"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transition hover:opacity-80"
                    >
                      <FooterMediumIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-8">
            <div className="flex flex-col text-body-16 text-[#726CA0] max-w-[434px] pr-24">
              <div className="font-ppmori">
                © {currentYear} Zebec. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
