import React from "react";
import { Gift2Image, GiftImage } from "../../../assets/images";
import { Button } from "../../shared/Button";
import * as Icons from "../../../assets/icons";

const Gift = () => {
  return (
    <div className="my-24">
      <div className="relative">
        <img src={GiftImage} alt="" className="hidden sm:block" />
        <img src={Gift2Image} alt="" className="block sm:hidden mx-auto" />
        <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center">
          <h2 className="lg:text-[40px] font-normal text-[36px] leading-[50px] text-white block sm:hidden max-w-[270px] mx-auto">
            Get Your Zebec Silver Card in Seconds with a Few Easy Steps!
          </h2>
          <h2 className="text-2xl font-normal lg:text-4xl xl:text-6xl text-white text-center hidden sm:block">
            Get Your Zebec Silver Card <br /> in Seconds with a Few <br />
            Easy Steps!
          </h2>
          <div className="">
            <a
              href="https://app.card.zebec.io/"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <Button
                title={"Get Zebec Silver"}
                className="green-button-border mt-5 lg:mt-10 text-sm lg:text-base rounded-full text-primary"
                startIcon={
                  <div
                    className={
                      "w-10 h-10 rounded-full bg-primary grid place-content-center"
                    }
                  >
                    <Icons.CheveronRightIcon className="w-5 h-5 text-white" />
                  </div>
                }
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gift;
