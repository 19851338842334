import React from "react";
import { Link } from "react-router-dom";
import {
  GradientWorldIcon,
  GradientShieldIcon,
  GradientSecurityIcon,
  GradientZeroFeeIcon,
  GradientCartIcon,
} from "../../../assets/icons";

const cards = [
  {
    title: "From Your Wallet to the World",
    desc: "Activate your card in seconds, fund from a wallet and start spending for your everyday purchases right away.",
    icon: <GradientWorldIcon />,
    span: "col-span-2",
  },
  {
    title: "Your Funds, Your Control",
    desc: "Keep command of your funds. Your privacy and autonomy are paramount, providing you with a secure way to spend worldwide.",
    icon: <GradientShieldIcon />,
    span: "col-span-2",
  },
  {
    title: "US Dollar Spend Unlocked",
    desc: "Get the stability and spending power of USD, regardless of your region or banking conditions. Zebec Silver is your key to a stable currency in an unstable world. Other fiat currencies are coming soon.",
    icon: <GradientSecurityIcon />,
    span: "col-span-2",
  },
  {
    title: "Zero Fees, Total Freedom",
    desc: "Maximize your spending power with Zebec's commitment to a no-fee structure. We’ve eliminated common charges such as sign-up fees, and transaction fees. FX fees are always variable and may be additional.",
    icon: <GradientZeroFeeIcon />,
    span: "col-span-2 lg:col-span-3",
  },
];
const IndustryFirst = () => {
  return (
    <div className="mt-24">
      <div className="sm:flex items-center justify-between gap-10">
        <div className="sm:max-w-[750px] text-zebec-primary text-2xl xl:text-[38px] xl:leading-[130%]">
          Non-custodial prepaid card,{" "}
          <strong className="font-normal gradient-text">
            supported worldwide by global{" "}
          </strong>
          <strong className="font-normal text-zebec-primary">
            Mastercard®
          </strong>{" "}
          <strong className="gradient-text font-normal gradient-text-border-b">
            <Link to="/zebec-silver-countries">network</Link>
          </strong>
        </div>
        <div className="text-zebec-primary sm:max-w-[427px] text-lg xl:text-[22px] leading-[150%] mt-10 sm:mt-0">
          With Zebec, your assets are ready to be spent in seconds, giving you
          unparalleled convenience worldwide.
        </div>
      </div>
      {/* cards */}
      <div className="mt-24">
        <div className="grid sm:grid-cols-4 lg:grid-cols-6 gap-6 xl:gap-10">
          {cards.map((data, index) => {
            const { icon, title, desc, span } = data;
            return (
              <div
                key={index}
                className={`zebec-cards-bg text-zebec-black rounded-[20px] p-6 xl:p-10 ${span}`}
              >
                <div>{icon}</div>
                <h2 className="text-zebec-primary font-normal text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
                  {title}
                </h2>
                <div className="text-zebec-secondary mt-5">{desc}</div>
              </div>
            );
          })}
          <div
            className={`zebec-cards-bg text-zebec-black rounded-[20px] p-6 xl:p-10 col-span-2 lg:col-span-3`}
          >
            <div>
              <GradientCartIcon />
            </div>
            <h2 className="text-zebec-primary font-normal text-[25px] lg:text-[29px] leading-[120%] mt-8 max-w-[283px]">
              Spend Anytime, Anywhere
            </h2>
            <div className="mt-5 text-zebec-secondary">
              <p>
                Our card breaks the boundaries by allowing you to spend US
                dollars at any merchant that accepts Mastercard globally.
                Whether you’re shopping online, dining out, or traveling, your
                Zebec card is your passport to a world of hassle-free payments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryFirst;
